.infoBubble{
  top: -37px;
}

.myHTML{

}
.imgBubble{
  border-radius: 2px;
  width: 225px;
}
.bubbleHeadline{
  color: #848585;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-left: 8px;
  margin-top: 4px;
}
.title{
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  width: 200px;
  hyphenate-limit-lines: 2;
  display: block;
  max-lines: 2;
  margin: 8px 16px 8px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.materials{
  margin-left: 4px;
}
#sand {
  border-radius: 2px;
  padding: 10px;
  background: #E3AD21;
  height: 2px;
  width: 2px;
  margin-left: 4px;
}

#ton {
  border-radius: 2px;
  padding: 10px;
  background: #F45F20;
  height: 2px;
  width: 2px;
  margin-left: 4px;
}

#kies {
  border-radius: 2px;
  padding: 10px;
  background: #575147;
  height: 2px;
  width: 2px;
  margin-left: 4px;
}
#boden {
  border-radius: 2px;
  padding: 10px;
  background: #AF6900;
  height: 2px;
  width: 2px;
  margin-left: 4px;
}
#ersatzbaustoff {
  border-radius: 2px;
  padding: 10px;
  background: #C100E0;
  height: 2px;
  width: 2px;
  margin-left: 4px;
}

//Positioning of the InfoBubble body in relation to the tail i.e. -9em centers the Body over the tail
.H_ib_body {
  margin-right: -9em;
  padding: 0;
  height: 250px;
}
.H_ib_tail {
  display:none;
}

//remove spacing reserved for the close-button
.H_ib_content{
  //margin-right: 16px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

//removes close-Button of the InfoBubble
.H_ib_close{
  display: none;
  padding: 0;
}

.color-icon {
  height: 12px;
  width: 12px;
  border-radius: 2px;
}

.header5 {
  color: #848585;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.material {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
}

.flowText {
  font-size: 12px;
  color: #B6B6B6;
}

.details-paragraphs-bubble {
  margin-bottom: 8px;
  //overflow-y: scroll;
  height: 100%;
}

//svg.H_icon{
//  display: none;
//  padding: 0;
//}
