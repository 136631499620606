@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src:
    url('../assets/fonts/poppins-v20-latin-300.woff') format('woff'),
    url('../assets/fonts/poppins-v20-latin-300.woff2') format('woff2')
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src:
    url('../assets/fonts/poppins-v20-latin-regular.woff') format('woff'),
    url('../assets/fonts/poppins-v20-latin-regular.woff2') format('woff2')
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('../assets/fonts/poppins-v20-latin-500.woff') format('woff'),
  url('../assets/fonts/poppins-v20-latin-500.woff2') format('woff2')
}
