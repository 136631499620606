@import "variables";

a {
  text-decoration: none;
}

h2 {
  font-weight: 600;
  font-size: 18px;
}

h3 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
}

h4 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0px;
}

h5 {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px;
}
