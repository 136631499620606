@import "variables";

.skeleton-box {
  text-indent: -999em;
  background-image: linear-gradient(90deg, #f1f1f1 0%, #f8f8f8 100%);
  background-size: 200%;

  animation-name: skeletonWave;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes skeletonWave {
    0% {
      background-position: 100%;
    }
    50% {
      background-position: 0;
    }
    100% {
      background-position: -100%;
    }
  }
}
