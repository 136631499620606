// Snackbar
.digiminSnack {
  background-color: $primary !important;
  color: $white !important;
  text-align: center;
  font-size: 12px;
  line-height: 23px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  min-width: 33vw !important;
  max-width: 95vw !important;

  &.snackWarn {
    background-color: $warn !important;
  }

  .mat-simple-snackbar-action {
    color: $white !important;
  }
}

.digiminSnack .mat-simple-snackbar {
  justify-content: center;
}


// Button
button.mat-primary {
  color: white !important;
}

.size-24 {
  width: 24px;
  height: 24px;
}

.size-28 {
  width: 24px;
  height: 24px;
}


// Form-Fields
mat-form-field {
  font-size: 14px !important;
  width: 100%;

  @supports (-moz-appearance:none) {
    input {
      height: 15.75px !important;
    }
  }
}

.mat-form-field .mat-form-field-flex {
  background-color: white;
}


// Dialog
.no-overflow-dialog {
  .mat-dialog-container {
    overflow-y: hidden;
  }
}

.cdk-overlay-container {
  z-index: 1002;
}

.booking-wizard-dialog {
  mat-dialog-container {
    padding: 0;
    overflow-y: hidden;
  }
}

// Stepper
.mat-horizontal-stepper-header-container {
  background: #FFFFFF;
  border-bottom: 1px solid #E8E9EC;
  margin-bottom: 16px;
  position: sticky;
  top: -16px;
  z-index: 301;

}

.mat-step-header .mat-step-icon-selected {
  color: white !important;
  background: #E2800E !important;
  border-radius: 16px;
}

.mat-step-header .mat-step-icon {
  background: #FFFFFF;
  border: 1px solid #E2800E;
  border-radius: 16px;
  color: #E2800E;
}


// Autocomplete
.map-search {
  mat-option {
    font-size: 12px;
    --icon-stroke: #B6B6B6;
    --icon-size: 20px;

    app-icon {
      margin-right: 1rem;
    }
  }
}

// Menu
.mat-menu-panel {
  max-width: none !important;
}
